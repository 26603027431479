import { useEffect } from 'react'

import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

const useFinished = () => {
  const { product } = useProposal()
  const productCode = product?.proposal?.productCode

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'proposta/sms-formalizacao',
      customLayer: {
        description: productCode
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      history.forward(1)
      e.preventDefault()

      goTo('/')
    })

    return () => {
      window.removeEventListener('popstate')
    }
  }, [])
}

export default useFinished
